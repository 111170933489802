.manage-slider{
    background-color: black;
    background-repeat: no-repeat;
    background-size:contain;
    height: 100%;
    width: 0vw;
    display: grid;
    grid-template-columns: 1fr;
    color: white;
    position: fixed;
    z-index: 102;
    align-items: stretch;
    justify-items: stretch; 
    right: 0;
    /* box-shadow: -20px 0px 30px #1969FF ; */
    overflow-y: scroll;
    transition: all 0.5s;
}

.manage-slider-active{
  transform: translateX(-300px);
  transition: all 0.4s;
}
.instructions{
    background: transparent;
    padding: 2%;
}
.instructions>ul>li{

    line-height: 30px;
}
.form-options{
  width:100%;
  display:flex;
  flex-direction: column;
  font-family: punk2;
}
.form-options>input,.form-options>div>input{
    flex-basis: 100%;
    background-color: #D3D3D3;
    border: 0px;
    font-family: punk2;
    font-style: normal;
    font-size: 1.3rem;
    color: black;
    padding: 1%;
    border-radius: 15px;
    max-width: inherit;
    min-width: 0;
}


.form-options>input::placeholder,.form-options>div>input::placeholder{
  font-family: punk2;
  font-size: 0.8rem;
  color: #828282;

}
.form-options>input:focus,.form-options>div>input:focus{
    outline: none;
}

.token-form{
    padding: 2%;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 1%;
}

.token-form,#main-con-manage{
  padding: 0% 20%;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 15px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  ::-webkit-scrollbar-corner{
    background-color: transparent;
  }

  @media screen and (max-width: 900px) {
    .manage-slider{
        width: 0;
        border-radius: 0px;
        overflow-y: scroll;
    }
    .instructions{
        margin: 0;
        }

    .token-form,#main-con-manage{
          padding: 0% 2%;
        }
  }