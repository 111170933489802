.mobile-menu{
    background-color: #D3D3D3;
    position: fixed;
    height: 100%;
    width: 100vw;
    flex-direction: column;
    text-align: center;
    z-index: 101;
    align-items: center;
    left: 0;
    display: flex;    
    top:-2000px;
    transition: top 0.5s ease-out;
    font-family: punk2;

}
.mobile-menu>p{
    font-weight: 900;
    font-size: 2rem;
    align-self: flex-end;
    margin-right: 20px;
}
.connect-btn-mob{
    display: flex;
    padding: 2%;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 15px;
    background-color:  black;;
    cursor: pointer;
    font-family: punk2;
    font-weight: 900;
}
@media (min-width:900px) {
    .mobile-menu{
        
        top:-2000px
    }
}